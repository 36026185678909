import React from 'react';
import styled from 'styled-components';
import {
  FacebookIcon,
  TwitterIcon,
  FacebookShareButton,
  TwitterShareButton
} from 'react-share';
import { useMemo } from 'react';

import { Flex, Text } from '../../shared/components';
import { PlayerPageTitle } from '../components';
import { textEnds } from '../../../services/Content';

const PlayerResultPageText = styled(Text)`
  line-height: 1.5;
`;

const PlayerResultPageShareBox = styled(Flex)`
  border: solid 0.8px rgba(255, 255, 255, 0.5);
  background-color: rgba(135, 160, 204, 0.35);
  width: 100%;
  max-width: 300px;
`;

const ShareIconsBox = styled(Flex)`
  > * {
    margin-right: ${props => props.theme.space[1]}

    &:last-child {
      margin-right: 0;
    }
  }
`;

export const PlayerResultPage = ({ place, score }) => {
  const shareText = useMemo(
    () =>
      `#wiedziałem, że to nie będzie trudne! Zdobyłem ${score} punkt${textEnds(score)} w QUIZie STS. #dobrzewieszwartowspierac #stsquiz`,
    [score]
  );

  return (
    <Flex
      alignItems="center"
      flexDirection="column"
      flexGrow={1}
      justifyContent="center"
      px={3}
    >
      <PlayerPageTitle color="orangeLight" mb={2}>
        Gratulacje!
      </PlayerPageTitle>
      <PlayerResultPageText
        mb={2}
        color="white"
        fontWeight="bold"
        uppercase
        textAlign="center"
      >
        Ukończyłeś quiz na{' '}
        <Text color="orangeLight" variant="raw">
          {place}
        </Text>{' '}
        miejscu
        <br />
        zdobywając{' '}
        <Text color="orangeLight" variant="raw">
          {score}
        </Text>{' '}
        {`punkt${textEnds(score)}`}!
      </PlayerResultPageText>
      <PlayerResultPageShareBox
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
        py={1}
      >
        <PlayerResultPageText
          mb={2}
          color="white"
          fontWeight="bold"
          uppercase
          textAlign="center"
        >
          Podziel się wynikiem!
        </PlayerResultPageText>
        <ShareIconsBox>
          <TwitterShareButton
            title={shareText}
            url={" "}
          >
            <TwitterIcon size="40" />
          </TwitterShareButton>
          <FacebookShareButton url={window.location.host} quote={shareText}>
            <FacebookIcon size="40" />
          </FacebookShareButton>
        </ShareIconsBox>
      </PlayerResultPageShareBox>
    </Flex>
  );
};
