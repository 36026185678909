export const textEnds = (p) => {
  const s = p.toString();

  if (s === "1") {
    return "";
  }

  if (s.endsWith("12") || s.endsWith("13") || s.endsWith("14")) {
    return "ów";
  }

  if (s.charAt(s.length - 1) === "2" || s.charAt(s.length - 1) === "3" || s.charAt(s.length - 1) === "4") {
    return "y";
  }

  return "ów";
}
