import styled from "styled-components";
import { color, typography, space, shadow, variant } from "styled-system";

export const Text = styled.span`
  ${color}
  ${shadow}
  ${space}
  ${typography}
  ${(props) => props.uppercase && `text-transform: uppercase`}

  ${variant({
    variants: {
      page: {
        fontFamily: "normal",
        letterSpacing: "0.43px",
        textTransform: "uppercase",
      },
      checkbox: {
        fontFamily: "normal",
        fontWeight: "normal",
        letterSpacing: "0.43px",
        textTransform: "uppercase",
        fontSize: "0.8em",
      },
      checkboxBold: {
        fontFamily: "normal",
        fontWeight: "bold",
        letterSpacing: "0.43px",
        textTransform: "uppercase",
        fontSize: "0.8em",
      },
      raw: {},
      title: {
        fontFamily: "title",
      },
      playerTimer: {
        fontFamily: "title",
        fontSize: "4em",
        textShadow: "title",
      },
      markAllOptions: {
        fontFamily: "normal",
        letterSpacing: "0.43px",
        textTransform: "uppercase",
        textDecoration: "underline",
        cursor: "pointer",
      },
    },
  })}
`;

export const Link = styled.a`
  ${color}
  ${shadow}
  ${space}
  ${typography}
  ${(props) => props.uppercase && `text-transform: uppercase`}

  ${variant({
    variants: {
      page: {
        fontFamily: "normal",
        letterSpacing: "0.43px",
        textTransform: "uppercase",
      },
      raw: {},
      title: {
        fontFamily: "title",
      },
      playerTimer: {
        fontFamily: "title",
        fontSize: "5em",
        textShadow: "title",
      },
    },
  })}
`;
