import React, { useEffect, useContext } from "react";
import styled, { ThemeProvider } from "styled-components";

import { PLAYER_THEME } from "../shared/theme";
import { PubSub } from "../PubSub";
import {
  PLAYER_LOGIN_PAGE,
  PLAYER_INFO_PAGE,
  PLAYER_LOBBY_PAGE,
  PLAYER_QUESTION_LOADING_PAGE,
  PLAYER_QUESTION_ANSWER_PAGE,
  PLAYER_QUESTION_RESULT_PAGE,
  PLAYER_RESULT_PAGE,
  GAME_QUESTION_ANSWER_EMIT,
  PLAYER_PAGE,
  PLAYER_LOGGED_IN_INFO_PAGE,
} from "../../api/constants";
import { PageContainer, Page, Fade, Loading } from "../shared/components";
import { gradientBackground, blueGradient } from "../shared/css";

import { PlayerInfoPage, PlayerLobbyPage } from "./Lobby";
import { LoginPage } from "./Login";
import { HomePage } from "./Home";
import {
  PlayerQuestionLoadingPage,
  PlayerQuestionAnswerPage,
  PlayerQuestionResultPage,
  PlayerLoggedInInfoPage,
} from "./Question";
import { PlayerResultPage } from "./Result";
import { PlayerContext } from "./PlayerContext";
import { useGameId } from "./useGameId";
import { PlayerBar } from "./Bar";

const PublicPageContainer = styled(PageContainer)`
  ${gradientBackground}
  position: relative;
  overflow: hidden;
`;

const GamePageContainer = styled(PageContainer)`
  ${blueGradient}
  position: relative;
  overflow-y: auto;
`;

export const PlayerPages = () => {
  const gameId = useGameId();
  const { score, setScore, page, setPage, reconnecting } = useContext(
    PlayerContext
  );

  const handleAnswerClick = (payload) => {
    PubSub.publish(GAME_QUESTION_ANSWER_EMIT, payload);
  };

  useEffect(() => {
    const subscribeFn = (payload) => {
      if (payload.score) {
        setScore(payload.score);
      }

      setPage({ name: payload.page, pagePayload: payload });
    };

    PubSub.subscribe(PLAYER_PAGE, subscribeFn);
  }, []);

  return (
    <ThemeProvider theme={PLAYER_THEME}>
      {(page.name === null || page.name === PLAYER_INFO_PAGE) && (
        <Page>
          <PublicPageContainer
            alignItems="center"
            flexDirection="column"
            flexGrow={1}
            justifyContent="center"
          >
            {page.name === null && !gameId && <HomePage />}
            {page.name === PLAYER_INFO_PAGE && (
              <PlayerInfoPage children={page.payload.content} />
            )}
          </PublicPageContainer>
        </Page>
      )}

      {(page.name === PLAYER_LOGIN_PAGE ||
        page.name === PLAYER_LOBBY_PAGE ||
        page.name === PLAYER_LOGGED_IN_INFO_PAGE ||
        page.name === PLAYER_QUESTION_LOADING_PAGE ||
        page.name === PLAYER_QUESTION_ANSWER_PAGE ||
        page.name === PLAYER_QUESTION_RESULT_PAGE ||
        page.name === PLAYER_RESULT_PAGE) && (
        <Page>
          {reconnecting && (
            <div
              style={{
                position: "absolute",
                bottom: "0.5em",
                left: "0.5em",
                zIndex: 1,
              }}
            >
              <Loading />
            </div>
          )}
          <PlayerBar showPlayerData={page.name !== PLAYER_LOGIN_PAGE} />
          <GamePageContainer
            alignItems="center"
            flex
            flexDirection="column"
            flexGrow={1}
            justifyContent="center"
          >
            <Fade show={page.name === PLAYER_LOGIN_PAGE}>
              <LoginPage />
            </Fade>
            <Fade show={page.name === PLAYER_LOBBY_PAGE}>
              <PlayerLobbyPage />
            </Fade>
            <Fade show={page.name === PLAYER_LOGGED_IN_INFO_PAGE}>
              <PlayerLoggedInInfoPage children={page.payload.content} />
            </Fade>
            <Fade show={page.name === PLAYER_QUESTION_LOADING_PAGE}>
              <PlayerQuestionLoadingPage
                animationTime={page.payload.loadingTime}
                questionContent={page.payload.question}
                questionNumber={page.payload.questionNumber}
              />
            </Fade>
            <Fade show={page.name === PLAYER_QUESTION_ANSWER_PAGE}>
              <PlayerQuestionAnswerPage
                loadingTime={page.payload.loadingTime}
                onAnswerClick={handleAnswerClick}
                questionAnswers={page.payload.questionAnswers}
                questionContent={page.payload.questionContent}
                questionId={page.payload.questionId}
                questionNumber={page.payload.questionNumber}
              />
            </Fade>
            <Fade show={page.name === PLAYER_QUESTION_RESULT_PAGE}>
              <PlayerQuestionResultPage
                place={page.payload.place}
                result={page.payload.isCorrectAnswer ? "correct" : "incorrect"}
                score={score}
              />
            </Fade>
            <Fade show={page.name === PLAYER_RESULT_PAGE}>
              <PlayerResultPage
                place={page.payload.place}
                score={page.payload.score}
              />
            </Fade>
          </GamePageContainer>
        </Page>
      )}
    </ThemeProvider>
  );
};
