import styled from "styled-components";

import { PageTitle } from "../../shared/components";

export const PlayerPageTitle = styled(PageTitle)`
  font-size: ${props => props.theme.fontSizes[1]};
  text-align: center;
`;

export const QuestionTitle = styled(PageTitle)`
  font-size: ${props => props.theme.fontSizes[1]};
  text-align: center;
  word-break: break-word;
`;
