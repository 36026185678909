import React from "react";
import styled from "styled-components";

import { Page, Text } from "../../shared/components";
import {
  EventPageLogo,
  EventPageContainer,
  EventPageContent,
  EventPageTitle,
} from "../components";
import { formatSeconds } from "../../../services";
import { useEventTime } from "../useEventTime";

const TimerText = styled(Text)`
  font-size: 4em;
`;

export const EventLobbyPage = ({ gamePin }) => {
  const { time } = useEventTime();

  return (
    <Page>
      <EventPageContainer>
        <EventPageLogo />
        <EventPageContent flexDirection="column" justifyContent="center">
          <EventPageTitle>
            Dołącz do gry na <br />
            <Text color="orangeLight" variant="raw" fontSize={1}>
              wiedzialem.com
            </Text>
            <br />i wpisz pin <br />
            <Text color="orangeLight" variant="raw" fontSize={2}>
              {gamePin}
            </Text>
          </EventPageTitle>
          {time && (
            <EventPageTitle>
              <TimerText color="white" textShadow="title">
                {formatSeconds(time)}
              </TimerText>
            </EventPageTitle>
          )}
        </EventPageContent>
      </EventPageContainer>
    </Page>
  );
};
