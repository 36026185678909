import styled, { keyframes, css } from 'styled-components';
import { space, layout } from 'styled-system';

export const ProgressBarWrapper = styled.div`
  ${layout}
  ${space}

  border: solid 0.8px rgba(255, 255, 255, 0.5);
`;

const fill = keyframes`
  0% {width: 0%}
  100% {width: 100%} 
`;

const animation = props => css`
  ${fill} ${props.animationTime}s linear;
`;

export const ProgressBar = styled.div`
  animation: ${animation};
  background-color: rgba(135, 160, 204, 0.35);
  height: 100%;
`;
