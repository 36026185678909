import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

import { EventPages, EventContextProvider } from "./views/Event";
import { PlayerContextProvider, PlayerPages } from "./views/Player";
import "./App.css";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path="/event/:gameId">
            <EventContextProvider>
              <EventPages />
            </EventContextProvider>
          </Route>
          <PlayerContextProvider>
            <PlayerPages />
          </PlayerContextProvider>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
