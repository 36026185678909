import { css } from "styled-components";

export const formStyles = css`
  letter-spacing: 0.43px;
  font-family: ${props => props.theme.fonts.normal};
  font-size: ${props => props.theme.fontSizes[0]};
  font-weight: bold;

  height: 35px;
  line-height: 35px;
`;
