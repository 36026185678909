import {
  GAME_QUESTION_LOAD_SUBSCRIBE,
  PLAYER_QUESTION_LOADING_PAGE,
  GAME_QUESTION_ANSWER_EMIT,
  GAME_QUESTION_ANSWER_SUBSCRIBE,
  PLAYER_QUESTION_ANSWER_PAGE,
  GAME_QUESTION_RESULT_SUBSCRIBE,
  PLAYER_QUESTION_RESULT_PAGE,
  GAME_READY_SUBSCRIBE,
  GAME_FINISH_SUBSCRIBE,
  PLAYER_RESULT_PAGE,
  PLAYER_PAGE,
  PLAYER_TIME,
  GAME_UPDATE_TIME_SUBSCRIBE,
  PLAYER_LOGGED_IN_INFO_PAGE
} from '../constants';
import { PLAYER_INFO_PAGE_CONTENT } from '../../resources';

const normalizeQuestionLoad = payload => ({
  question: payload.question,
  questionNumber: payload.qnum,
  loadingTime: payload.time
});

const normalizeQuestionAnswer = payload => ({
  questionAnswers: payload.answers,
  questionContent: payload.question,
  questionId: payload.qid,
  questionNumber: payload.qnum,
  loadingTime: payload.time
});

const normalizeQuestionResult = payload => ({
  isCorrectAnswer: payload.is_good_answer,
  questionNumber: payload.qnum,
  place: payload.place,
  score: payload.score
});

const normalizeResult = payload => ({
  place: payload.place,
  score: payload.score
});

/* 
  Attach events to Websocket and PubSub (proxy)
  @param {Websocket} connection - Websocket instance
  @param {PubSub} pubSub - PubSub instance
*/
export const attachGameEvents = (connection, pubSub) => {
  /* 
    PAYLOAD: {}
  */
  connection.on(GAME_READY_SUBSCRIBE, () => {
    // TODO
    pubSub.publish(PLAYER_PAGE, {
      page: PLAYER_LOGGED_IN_INFO_PAGE,
      content: PLAYER_INFO_PAGE_CONTENT.GAME_READY
    });
  });

  /* 
    PAYLOAD: { 
      question: string;
      qnum: number;
      time: number; 
    }
  */
  connection.on(GAME_QUESTION_LOAD_SUBSCRIBE, payload => {
    pubSub.publish(PLAYER_PAGE, {
      page: PLAYER_QUESTION_LOADING_PAGE,
      ...normalizeQuestionLoad(payload)
    });
  });

  /* 
    EMIT
    PAYLOAD: {
      answer_id: number; (answer id)
      question_num: number; (question number)
      q_id: number; (question id)
      uid: number; (user id)
      ut: string; (user token)
      pin: string; (game pin)
      t: string; (token)
    }
  */
  pubSub.subscribe(GAME_QUESTION_ANSWER_EMIT, payload => {
    connection.emit(
      GAME_QUESTION_ANSWER_EMIT,
      {
        answer_id: payload.answerId,
        question_num: payload.questionNumber,
        q_id: payload.questionId
      },
      {
        applyUserData: true
      }
    );
  });

  /* 
    PAYLOAD: {
      qnum: number;
      qid: number;
      time: number;
      question: string;
      answers: string[];
    }
  */
  connection.on(GAME_QUESTION_ANSWER_SUBSCRIBE, payload => {
    pubSub.publish(PLAYER_PAGE, {
      page: PLAYER_QUESTION_ANSWER_PAGE,
      ...normalizeQuestionAnswer(payload)
    });
  });

  /* 
    PAYLOAD: {
      qnum: number;
      place: number;
      score: number;
      is_good_answer: boolean;
      is_personal: boolean;
    }
  */
  connection.on(GAME_QUESTION_RESULT_SUBSCRIBE, payload => {
    pubSub.publish(PLAYER_PAGE, {
      page: PLAYER_QUESTION_RESULT_PAGE,
      ...normalizeQuestionResult(payload)
    });
  });

  /* 
    PAYLOAD: {
      place: number;
      score: number;
    }
  */
  connection.on(GAME_FINISH_SUBSCRIBE, payload => {
    pubSub.publish(PLAYER_PAGE, {
      page: PLAYER_RESULT_PAGE,
      ...normalizeResult(payload)
    });
  });

  /* 
    PAYLOAD: {
      t: number;
    }
  */
  connection.on(GAME_UPDATE_TIME_SUBSCRIBE, payload => {
    pubSub.publish(PLAYER_TIME, {
      time: payload.t
    });
  });
};
