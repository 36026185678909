import { useParams } from 'react-router-dom';
import { useCallback } from 'react';

import { PubSub } from '../views/PubSub';

import { Websocket } from './Websocket';
import { attachEventEvents } from './events';

const PORT = 2053;
const HOST = window.location.hostname;
const URL = `${HOST}:${PORT}`;

export function useEventWebsockets() {
  const { gameId } = useParams();

  const connect = useCallback(() => {
    const connection = new Websocket(gameId, URL).open();

    attachEventEvents(connection, PubSub);

    return connection;
  }, [gameId]);

  return { connect };
}
