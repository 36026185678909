import { useCallback } from 'react';

import { PubSub } from '../views/PubSub';

import { Websocket } from './Websocket';
import {
  attachGameEvents,
  attachRegisterEvents,
  attachInitEvents
} from './events';

const PORT = 2053;
const HOST = window.location.hostname;
const URL = `${HOST}:${PORT}`;

export function usePlayerWebsockets(gameId) {
  const connect = useCallback(() => {
    const connection = new Websocket(gameId, URL).open();

    attachInitEvents(connection, PubSub);
    attachRegisterEvents(connection, PubSub);
    attachGameEvents(connection, PubSub);

    return connection;
  }, [gameId]);

  return { connect };
}
