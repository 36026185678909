import React from 'react';

import { Icon } from '../Icon';

import { AnswerBox } from './AnswerBox';

export const AnswerC = ({ children, ...rest }) => {
  return (
    <AnswerBox {...rest}>
      <Icon name="speedway" />
      {children}
    </AnswerBox>
  );
};
