import React from "react";

import { STSLogo, Flex } from "../../shared/components";
import { QuestionTitle } from "../components";

export const PlayerInfoPage = ({ children }) => {
  return (
    <>
      <STSLogo width="200px" />
      <Flex alignItems="center" flexDirection="column" justifyContent="center">
        <QuestionTitle color="white" textShadow="title" px={1}>
          {children}
        </QuestionTitle>
      </Flex>
    </>
  );
};
