// STORAGE
export const API_TOKEN_KEY = 't';
export const USER_TOKEN_KEY = 'ut';
export const USER_ID_KEY = 'uid';
export const USER_NICK_KEY = 'n';
export const USER_SCORE_KEY = 's';
export const GAME_PIN_KEY = 'pin';

// GENERAL WEBSOCKETS
export const CONNECT_TOPIC_SUBSCRIBE = 'connect';
export const CONNECT_ERROR_TOPIC_SUBSCRIBE = 'connect_error';
export const DISCONNECT_TOPIC_SUBSCRIBE = 'disconnect';
export const RECONNECTING_SUBSCRIBE = 'reconnecting';
export const RECONNECT_SUCCESS_SUBSCRIBE = 'reconnect';
export const RECONNECT_ERROR_SUBSCRIBE = 'reconnect_error';

// PLAYER WEBSOCKETS
export const DISPLAY_GAME_TOPIC_EMIT = 'DGS';
export const DISPLAY_GAME_TOPIC_SUBSCRIBE = 'DGR';

export const GAME_READY_SUBSCRIBE = 'QR';
export const GAME_QUESTION_LOAD_SUBSCRIBE = 'QQL';
export const GAME_QUESTION_ANSWER_SUBSCRIBE = 'QQD';
export const GAME_QUESTION_ANSWER_EMIT = 'UAS';
export const GAME_QUESTION_RESULT_SUBSCRIBE = 'QQR';
export const GAME_FINISH_SUBSCRIBE = 'QF';

export const GAME_WAITING_FOR_START_EVENT = 'G_WAITING';
export const GAME_CANT_JOIN_EVENT = 'G_CANTJOIN';

export const USER_REGISTER_TOPIC_EMIT = 'URS';
export const USER_REGISTER_TOPIC_SUBSCRIBE = 'URR';
export const USER_REGISTER_SUCCESS_EVENT = 'R_USER';
export const USER_REGISTER_VALIDATION_EVENT = 'R_VALIDATION';
export const USER_BAN_TOPIC_SUBSCRIBE = 'UB';

export const GAME_UPDATE_TIME_SUBSCRIBE = 'UT';

// PLAYER EVENTS
export const PLAYER_PAGE = 'PLAYER_PAGE';
export const PLAYER_TIME = 'PLAYER_TIME';
export const PLAYER_RECONNECTING = 'PLAYER_RECONNECTING';
export const PLAYER_RECONNECTED = 'PLAYER_RECONNECTED';
export const PLAYER_VALIDATION = 'PLAYER_VALIDATION';

// PLAYER PAGES
export const PLAYER_LOGIN_PAGE = 'PLAYER_LOGIN';
export const PLAYER_INFO_PAGE = 'PLAYER_INFO_PAGE';
export const PLAYER_LOBBY_PAGE = 'PLAYER_LOBBY_PAGE';
export const PLAYER_LOGGED_IN_INFO_PAGE = 'PLAYER_LOGGED_IN_INFO_PAGE';
export const PLAYER_QUESTION_LOADING_PAGE = 'PLAYER_QUESTION_LOADING_PAGE';
export const PLAYER_QUESTION_ANSWER_PAGE = 'PLAYER_QUESTION_ANSWER_PAGE';
export const PLAYER_QUESTION_RESULT_PAGE = 'PLAYER_QUESTION_RESULT_PAGE';
export const PLAYER_RESULT_PAGE = 'PLAYER_RESULT_PAGE';

// EVENT WEBSOCKETS
export const EVENT_SCREEN_INIT_EMIT = 'BSI';
export const EVENT_SCREEN_INIT_SUBSCRIBE = 'BSIR';
export const EVENT_SCREEN_READY_SUBSCRIBE = 'AGIR';
export const EVENT_SCREEN_QUESTION_LOAD_SUBSCRIBE = 'AQL';
export const EVENT_SCREEN_QUESTION_ANSWER_SUBSCRIBE = 'ADMIN_QUESTION_DISPLAY';
export const EVENT_SCREEN_QUESTION_RESULT_SUBSCRIBE = 'AQR';
export const EVENT_SCREEN_TOP_RANK_SUBSCRIBE = 'AGF';
export const EVENT_SCREEN_UPDATE_TIME_SUBSCRIBE = 'UT';

// EVENT EVENTS
export const EVENT_PAGE = 'EVENT_PAGE';
export const EVENT_TIME = 'EVENT_TIME';
export const EVENT_RECONNECTING = 'EVENT_RECONNECTING';
export const EVENT_RECONNECTED = 'EVENT_RECONNECTED';

// EVENT PAGES
export const EVENT_LOBBY_PAGE = 'EVENT_LOBBY_PAGE';
export const EVENT_INFO_PAGE = 'EVENT_INFO_PAGE';
export const EVENT_QUESTION_LOADING_PAGE = 'EVENT_QUESTION_LOADING_PAGE';
export const EVENT_QUESTION_ANSWER_PAGE = 'EVENT_QUESTION_ANSWER_PAGE';
export const EVENT_QUESTION_RESULT_PAGE = 'EVENT_QUESTION_RESULT_PAGE';
export const EVENT_RANK_PAGE = 'EVENT_RANK_PAGE';
export const EVENT_TOP_SCORE_PAGE = 'EVENT_TOP_SCORE_PAGE';

export const PUBLIC_EVENTS = [
  CONNECT_TOPIC_SUBSCRIBE,
  DISCONNECT_TOPIC_SUBSCRIBE,
  DISPLAY_GAME_TOPIC_SUBSCRIBE,
  USER_REGISTER_TOPIC_SUBSCRIBE,
  EVENT_SCREEN_INIT_SUBSCRIBE,
  EVENT_SCREEN_READY_SUBSCRIBE,
  EVENT_SCREEN_QUESTION_LOAD_SUBSCRIBE,
  EVENT_SCREEN_QUESTION_ANSWER_SUBSCRIBE,
  EVENT_SCREEN_QUESTION_RESULT_SUBSCRIBE,
  EVENT_SCREEN_TOP_RANK_SUBSCRIBE,
  EVENT_SCREEN_UPDATE_TIME_SUBSCRIBE
];
