import React from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';

import { flexStyle, transparentBackground } from '../../css';

const StyledBox = styled.div`
  ${space}
  ${flexStyle}
  ${transparentBackground}
  height: 100%;
  position: relative;
  overflow: hidden;
  padding: 1em;
  text-align: center;

  ${props => props.clicked && `background-color: rgba(135, 160, 204, 0.8);`}

  img {
    position: absolute;
    opacity: 0.1;
    width: 150px;
    height: 150px;
  }
`;

export const AnswerBox = ({ children, ...rest }) => {
  return (
    <StyledBox
      alignItems="center"
      flex
      flexGrow={1}
      justifyContent="center"
      {...rest}
    >
      {children}
    </StyledBox>
  );
};
