import { css } from 'styled-components';

export const blueGradient = css`
  background: rgb(0, 112, 225);
  background: linear-gradient(
    82deg,
    rgba(0, 112, 225, 1) 0%,
    rgba(0, 62, 164, 1) 100%
  );
`;

export const orangeGradient = css`
  background: rgb(255, 165, 0);
  background: linear-gradient(
    82deg,
    rgba(255, 165, 0, 1) 0%,
    rgba(245, 126, 32, 1) 100%
  );
`;

export const transparent = css`
  border: solid 0.8px rgba(255, 255, 255, 0.5);
  background-color: rgba(135, 160, 204, 0.35);
`;
