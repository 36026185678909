import { useEffect, useState } from "react";

import { PubSub } from "../PubSub";
import { PLAYER_TIME } from "../../api/constants";

export function usePlayerTime(initialValue = null) {
  const [t, setT] = useState(initialValue);
  useEffect(() => {
    const token = PubSub.subscribe(PLAYER_TIME, payload => {
      setT(payload.time);
    });

    return () => {
      PubSub.unsubscribe(token);
    };
  }, [setT]);

  return { time: t };
}
