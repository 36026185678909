import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import {
  Flex,
  Input,
  Text,
  Label,
  LoginButton,
  Box,
  Link,
} from "../../../shared/components";
import { PlayerPageTitle } from "../../components";
import { PLAYER_VALIDATION } from "../../../../api/constants";
import { PubSub } from "../../../PubSub";
import { useGameId } from "../../useGameId";

import { Checkbox } from "./Checkbox";

const Footer = styled(Box)`
  text-align: center;
`;

// eslint-disable-next-line
const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const PHONE_REGEX = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{3})/;

export const LoginPlayerData = ({ onSubmit }) => {
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    nick: "",
    email: "",
    phone: undefined,
    dataProcessingAgreement: false,
    adultAgreement: false,
    rulesAgreement: false,
    marketAgreement: false,
    allMarked: false,
  });

  const gameId = useGameId();

  useEffect(() => {
    const token = PubSub.subscribe(PLAYER_VALIDATION, (payload) => {
      if (payload[0]) {
        setErrors((errors) => ({
          ...errors,
          [payload[0].f]: payload[0].r,
        }));
      }
    });

    return () => {
      PubSub.unsubscribe(token);
    };
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((formData) => ({ ...formData, [name]: value }));
  };

  const handleCheck = (event) => {
    const { name } = event.target;
    setFormData((formData) => ({ ...formData, [name]: !formData[name] }));
  };

  const handleCheckEverything = () => {
    setFormData((formData) => {
      if (formData.allMarked) {
        return {
          ...formData,
          dataProcessingAgreement: false,
          adultAgreement: false,
          rulesAgreement: false,
          marketAgreement: false,
          allMarked: false,
        };
      } else {
        return {
          ...formData,
          dataProcessingAgreement: true,
          adultAgreement: true,
          rulesAgreement: true,
          marketAgreement: true,
          allMarked: true,
        };
      }
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const errors = Object.entries(formData).reduce((prev, [name, value]) => {
      if (name === "email") {
        return EMAIL_REGEX.test(value)
          ? prev
          : { ...prev, [name]: "Nieprawidłowy email" };
      } else if (name === "phone") {
        return PHONE_REGEX.test(value)
          ? prev
          : { ...prev, [name]: "Nieprawidłowy telefon" };
      } else if (
        name === "dataProcessingAgreement" ||
        name === "adultAgreement" ||
        name === "nick" ||
        name === "rulesAgreement"
      ) {
        return value ? prev : { ...prev, [name]: "To pole jest wymagane" };
      }

      return prev;
    }, {});

    setErrors(errors);

    if (Object.entries(errors).length === 0) {
      onSubmit(formData);
    }
  };

  return (
    <Box width="100%" height="100%" px={0} py={3}>
      <PlayerPageTitle color="orangeLight" textShadow="title" mb={2}>
        Rejestracja
        <br />
        do gry
      </PlayerPageTitle>
      <Flex flexDirection="column" maxWidth="250px" mx="auto">
        <form onSubmit={handleSubmit}>
          <Box mb={2}>
            <Input
              bg="white"
              color="blueLight"
              py={0}
              placeholder="Nick"
              width="100%"
              name="nick"
              value={formData.nick}
              onChange={handleChange}
              notValid={errors.nick}
            />
            {errors.nick && (
              <Text
                color="white"
                fontSize="0.8em"
                fontWeight="bold"
                textShadow="checkbox"
                uppercase
              >
                {errors.nick}
              </Text>
            )}
          </Box>
          <Box mb={2}>
            <Input
              bg="white"
              color="blueLight"
              py={0}
              placeholder="Email"
              width="100%"
              name="email"
              value={formData.email}
              onChange={handleChange}
              notValid={errors.email}
            />
            {errors.email && (
              <Text
                color="white"
                fontSize="0.8em"
                fontWeight="bold"
                textShadow="checkbox"
                uppercase
              >
                {errors.email}
              </Text>
            )}
          </Box>
          <Box mb={2}>
            <Input
              bg="white"
              color="blueLight"
              py={0}
              placeholder="Telefon"
              width="100%"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              type="number"
              notValid={errors.phone}
            />
            {errors.phone && (
              <Text
                color="white"
                fontSize="0.8em"
                fontWeight="bold"
                textShadow="checkbox"
                uppercase
              >
                {errors.phone}
              </Text>
            )}
          </Box>
          <Box mb={0}>
            <Label>
              <Checkbox
                checked={formData.allMarked}
                name="allMarked"
                onChange={handleCheckEverything}
                notValid={errors.allMarked}
              />
              <Text
                color="white"
                fontWeight="bold"
                ml={0}
                textShadow="checkbox"
                variant="checkboxBold"
              >
                Zaznacz wszystkie opcje
              </Text>
            </Label>
          </Box>
          <Box mb={0}>
            <Label>
              <Checkbox
                checked={formData.dataProcessingAgreement}
                name="dataProcessingAgreement"
                onChange={handleCheck}
                notValid={errors.dataProcessingAgreement}
              />
              <Text
                color="white"
                fontWeight="bold"
                ml={0}
                textShadow="checkbox"
                variant="checkbox"
              >
                Wyrażam zgodę na przetwarzanie danych osobowych*
              </Text>
            </Label>
          </Box>
          <Box mb={0}>
            <Label>
              <Checkbox
                checked={formData.adultAgreement}
                name="adultAgreement"
                onChange={handleCheck}
                notValid={errors.adultAgreement}
              />
              <Text
                color="white"
                fontWeight="bold"
                ml={0}
                textShadow="checkbox"
                variant="checkbox"
              >
                Mam ukończone 18 lat*
              </Text>
            </Label>
          </Box>
          <Box mb={0}>
            <Label>
              <Checkbox
                checked={formData.rulesAgreement}
                name="rulesAgreement"
                onChange={handleCheck}
                notValid={errors.rulesAgreement}
              />
              <Text
                color="white"
                fontWeight="bold"
                ml={0}
                textShadow="checkbox"
                variant="checkbox"
              >
                Akceptuje{" "}
                <Link
                  color="white"
                  href={`https://panel.wiedzialem.com/rulesurl/${gameId}`}
                  target="_blank"
                >
                  regulamin
                </Link>
                *
              </Text>
            </Label>
          </Box>
          <Box>
            <Label>
              <Checkbox
                checked={formData.marketAgreement}
                name="marketAgreement"
                onChange={handleCheck}
                notValid={errors.marketAgreement}
              />
              <Text
                color="white"
                fontWeight="bold"
                ml={0}
                textShadow="checkbox"
                variant="checkbox"
              >
                Wyrażam zgodę na otrzymywanie informacji handlowych
              </Text>
            </Label>
          </Box>
          <LoginButton mt={1} width="100%" type="submit">
            Zatwierdź
          </LoginButton>
        </form>
      </Flex>
      <Footer width="100%" textAlign="center" mt={3} px={3}>
        <Text
          color="white"
          fontWeight="bold"
          textShadow="checkbox"
          variant="checkboxBold"
        >
          STS to legalny bukmacher. Gra u nielegalnych podlega karze. Hazard
          może uzależniać.
        </Text>
      </Footer>
    </Box>
  );
};

LoginPlayerData.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};
