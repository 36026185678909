import React from 'react';

import { Flex, Page, Text } from '../../../shared/components';
import {
  EventPageContainer,
  EventPageLogo,
  EventPageContent,
  EventPageTitle,
  EventPageSubTitle
} from '../../components';
import { useEventTime } from '../../useEventTime';
import { TimerBox } from '../components';

export const EventQuestionLoadingPage = ({
  animationTime,
  questionContent,
  questionNumber
}) => {
  const { time } = useEventTime(animationTime);

  return (
    <Page>
      <EventPageContainer>
        <EventPageLogo />
        <EventPageContent flexDirection="column">
          <EventPageTitle>
            Pytanie <Text color="orangeLight" variant="raw">{questionNumber}</Text>
          </EventPageTitle>
          <Flex
            justifyContent="center"
            flexDirection="column"
            flexGrow={1}
            px={2}
          >
            <EventPageSubTitle uppercase textAlign="center">
              {questionContent}
            </EventPageSubTitle>
            <Flex justifyContent="center" mt={1} mb={1}>
              <TimerBox fontSize={3} fontWeight="bold">
                <Text color="orangeLight" variant="page">{time}</Text>
              </TimerBox>
            </Flex>
            <EventPageSubTitle uppercase textAlign="center">
              Przygotuj się...
            </EventPageSubTitle>
          </Flex>
        </EventPageContent>
      </EventPageContainer>
    </Page>
  );
};
