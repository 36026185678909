import React from 'react';

import {
  Page,
  Flex,
  AnswerC,
  Text
} from '../../../shared/components';
import {
  EventPageContainer,
  EventPageLogo,
  EventPageContent,
  EventPageTitle,
  EventPageSubTitle
} from '../../components';

export const EventQuestionResultPage = ({ questionNumber }) => {
  return (
    <Page>
      <EventPageContainer>
        <EventPageLogo />
        <EventPageContent flexDirection="column">
          <Flex justifyContent="center" px={2}>
            <EventPageTitle>
              Pytanie <Text color="orangeLight" variant="raw">{questionNumber}</Text>
            </EventPageTitle>
          </Flex>
          <Flex
            justifyContent="center"
            flexDirection="column"
            flexGrow={1}
            pt={1}
            px={2}
          >
            <EventPageSubTitle uppercase textAlign="center">
              Poprawna odpowiedź:
            </EventPageSubTitle>
            <Flex flexGrow={1} mt={1} maxHeight="60%">
              <AnswerC>
                <Text color="white" fontSize={1} variant="page">
                  TUTAJ POPRAWNA ODPOWIEDZ
                </Text>
              </AnswerC>
            </Flex>
          </Flex>
        </EventPageContent>
      </EventPageContainer>
    </Page>
  );
};
