import React from 'react';
import styled from 'styled-components';
import { layout, position, space } from 'styled-system';

import primaryLogo from './sts-logo-primary.png';

const LogoImg = styled.img`
  ${layout}
  ${position}
  ${space}
  width: ${props => props.width || `auto`};
`;

export const STSLogo = (props) => {
  return <LogoImg src={primaryLogo} {...props} />;
};

