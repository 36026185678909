import React from 'react';

import { Flex } from '../../../shared/components';
import { PlayerPageTitle } from '../../components';

export const PlayerLoggedInInfoPage = ({ children }) => {
  return (
    <Flex alignItems="center" flexDirection="column" justifyContent="center">
      <PlayerPageTitle color="white" textShadow="title">
        {children}
      </PlayerPageTitle>
    </Flex>
  );
};
