import styled from "styled-components";
import { layout } from "styled-system";

import { transparentBackgroundLight } from "../../css";

export const Button = styled.button`
  ${layout}  
  ${transparentBackgroundLight}
  border-radius: ${props => props.theme.radii.borderRadius};
  color: ${props => props.theme.colors.white};
  text-shadow: ${props => props.theme.shadows.button};

  padding: 0;
  text-transform: uppercase;
`;
