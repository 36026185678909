import io from 'socket.io-client';

import { getItem, setItem } from '../services';

import {
  USER_ID_KEY,
  USER_TOKEN_KEY,
  API_TOKEN_KEY,
  PUBLIC_EVENTS,
  GAME_PIN_KEY
} from './constants';

export class Websocket {
  constructor(gameId, url) {
    this.pin = gameId;
    this.url = url;

    this.token = getItem(API_TOKEN_KEY);
    this.userId = getItem(USER_ID_KEY) || -1;
    this.userToken = getItem(USER_TOKEN_KEY) || '';
  }

  /* 
    Emits event to server
    @param {string} topic
    @param {object} payload
    @param {object} options - options.applyUserData - applies user auth data if true
  */
  emit = (topic, payload, options = {}) => {
    const dto = options.applyUserData
      ? { ...payload, uid: this.userId, ut: this.userToken, ir: !!this.isLoggedIn() }
      : payload;

    // console.log(
    //   `%c emit ${topic}, serialized payload: `,
    //   'background: #aaa; color: #444',
    //   this.serializeData(dto)
    // );

    this.connection.emit(topic, this.serializeData(dto));
  };

  on = (topic, fn) => {
    this.connection.on(topic, payload => {
      // console.log(
      //   `%c on ${topic}, payload: `,
      //   'background: #fff; color: #444',
      //   payload
      // );

      if (PUBLIC_EVENTS.includes(topic)) {
        fn(payload);
      } else {
        if (this.isLoggedIn()) {
          fn(payload);
        }
      }
    });
  };

  open = () => {
    this.connection = io.connect(this.url, { secure: true });
    return this;
  };

  isLoggedIn = () => {
    const userId = getItem(USER_ID_KEY);
    const userToken = getItem(USER_TOKEN_KEY);
    const gamePin = getItem(GAME_PIN_KEY);
    return userId && userToken && gamePin === this.pin;
  };

  serializeData = data => ({
    ...data,
    pin: this.pin,
    t: this.token
  });

  setUserData = data => {
    this.userId = data.r[0];
    this.userToken = data.r[1];
  };

  setToken = token => {
    this.token = token;
  };
}
