import React from 'react';
import PropTypes from 'prop-types';

import { Text } from '../../../shared/components';
import { PlayerPageTitle } from '../../components';

import { CorrectCharacter, IncorrectCharacter } from './components';

export const PlayerQuestionResultPage = ({
  place,
  result,
  score
}) => {
  const isCorrectAnswer = result === 'correct';

  return (
    <>
      <Text color="orangeLight" fontSize={3} mb={0}>
        {isCorrectAnswer ? <CorrectCharacter /> : <IncorrectCharacter />}
      </Text>
      <PlayerPageTitle color="orangeLight" mb={2} px={1}>
        {isCorrectAnswer ? 'Odpowiedź prawidłowa!' : 'Odpowiedź błędna!'}
      </PlayerPageTitle>
      <Text color="white" mb={2} fontWeight="bold" variant="page">
        Masz {score} punkty
      </Text>
      <Text color="white" variant="page">
        Jesteś aktualnie na {place} miejscu!
      </Text>
    </>
  );
};

PlayerQuestionResultPage.propTypes = {
  result: PropTypes.oneOf(['correct', 'incorrect']).isRequired
};
