import React, { useEffect, useState } from "react";
import { Flex } from "../Layout";

export const Fade = ({ show, children }) => {
  const [shouldRender, setRender] = useState(show);

  useEffect(() => {
    if (show) setRender(true);
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) setRender(false);
  };

  return (
    shouldRender && (
      <Flex
        alignItems="center"
        flex
        flexDirection="column"
        flexGrow={1}
        justifyContent="center"
        style={{ position: "absolute", animation: `${show ? "fadeIn" : "fadeOut"} 1s`, "overflowY": "auto", top: 0, minHeight: "100%" }}
        onAnimationEnd={onAnimationEnd}
      >
        {children}
      </Flex>
    )
  );
};
