import React, { createContext, useEffect } from 'react';

import { usePlayerWebsockets } from '../../api';
import { setItem, getItem } from '../../services';
import { USER_NICK_KEY, USER_SCORE_KEY, PLAYER_RECONNECTING, PLAYER_RECONNECTED } from '../../api/constants';

import { useGameId } from './useGameId';
import { useReducer } from 'react';
import { PubSub } from '../PubSub';

export const PlayerContext = createContext({});

const initialState = {
  score: 0,
  nick: null,
  page: null,
  pagePayload: {},
  reconnecting: false,
};

function reducer(state, action) {
  switch (action.type) {
    case 'page':
      return {
        ...state,
        page: action.payload.name,
        pagePayload: {
          ...state.pagePayload,
          ...action.payload.pagePayload
        }
      };
    case 'nick':
      return { ...state, nick: action.payload.nick };
    case 'score':
      return { ...state, score: action.payload.score };
    case 'reconnecting':
      return { ...state, reconnecting: action.payload.reconnecting };
    default:
      throw new Error();
  }
}

export const PlayerContextProvider = ({ children }) => {
  const gameId = useGameId();
  const [state, dispatch] = useReducer(reducer, initialState);
  const { connect } = usePlayerWebsockets(gameId);

  const handleSetNick = nick => {
    setItem(USER_NICK_KEY, nick);
    dispatch({ type: "nick", payload: { nick } });
  };

  const handleSetScore = score => {
    setItem(USER_SCORE_KEY, score);
    dispatch({ type: "score", payload: { score } });
  };

  const handleSetPage = ({ name, pagePayload }) => {
    dispatch({ type: "page", payload: { name, pagePayload } });
  }

  useEffect(() => {
    const init = async () => {
      if (gameId) {
        connect();

        const nickStorage = await getItem(USER_NICK_KEY);
        const scoreStorage = await getItem(USER_SCORE_KEY);

        if (nickStorage) {
          dispatch({ type: "nick", payload: { nick: nickStorage } });
        }

        if (scoreStorage) {
          dispatch({ type: "score", payload: { score: scoreStorage } });
        }
      }
    }

    PubSub.subscribe(PLAYER_RECONNECTING, () => {
      dispatch({ type: "reconnecting", payload: { reconnecting: true } });
    });

    PubSub.subscribe(PLAYER_RECONNECTED, () => {
      dispatch({ type: "reconnecting", payload: { reconnecting: false } });
    });

    init();
  }, [connect, gameId]);

  return (
    <PlayerContext.Provider
      value={{
        nick: state.nick,
        setNick: handleSetNick,
        score: state.score,
        setScore: handleSetScore,
        page: {
          name: state.page,
          payload: state.pagePayload,
        },
        setPage: handleSetPage,
        reconnecting: state.reconnecting,
      }}
    >
      {children}
    </PlayerContext.Provider>
  );
};
