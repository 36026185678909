export const PLAYER_INFO_PAGE_CONTENT = {
  INACTIVE: 'Gra nie jest aktywna',
  INPROGRESS: 'Nie można dołączyć w trakcie rozgrywki',
  FINISHED: 'Gra zakończona',
  NOTEXIST: 'Gra nie istnieje',
  GAME_READY: 'Gra rozpocznie się za chwile...',
  WAITING_FOR_EVENT: 'Oczekiwanie na prowadzącego',
  LOST_SERVER_CONNECTION: 'Utracono połączenie z serwerem',
  CONNECT_ERROR: 'Brak połączenia z serwerem',
  BAN: "Zostałeś wyrzucony przez administratora gry.",
  TOOMANY: "Limit graczy został osiągnięty. Nie możesz dołączyć."
};

export const EVENT_INFO_PAGE_CONTENT = {
  INACTIVE: 'Gra nie jest aktywna',
  INPROGRESS: 'Nie można dołączyć w trakcie rozgrywki',
  FINISHED: 'Gra zakończona',
  NOTEXIST: 'Gra nie istnieje',
  GAME_READY: 'Gra rozpocznie sią za chwile...',
  LOST_SERVER_CONNECTION: 'Utracono połączenie z serwerem',
  CONNECT_ERROR: 'Brak połączenia z serwerem'
};
