const BASE_THEME = {
  breakpoints: ["900px", "1400px", "1920px"],
  colors: {
    blueLight: "#0070E1",
    blueDark: "#003EA4",
    orangeLight: "#ffa500",
    orangeDark: "#f57e20",
    white: "#ffffff"
  },
  fonts: {
    normal: "'TT Supermolot Neue', serif",
    title: "'TT Supermolot Neue Exp DBold', serif"
  },
  shadows: {
    button: "0 0.5px 0.5px rgba(0, 0, 0, 0.15)",
    checkbox: "0 0.5px 0.5px rgba(0, 0, 0, 0.25)",
    title: "0 0.5px 0.5px rgba(0, 0, 0, 0.5)"
  },
  radii: {
    borderRadius: "3px"
  }
};

export const EVENT_THEME = {
  ...BASE_THEME,
  fontSizes: ["1.5em", "2em", "2.5em", "3.5em"],
  space: ["1em", "2em", "5em", "7.5em"],
};

export const PLAYER_THEME = {
  ...BASE_THEME,
  fontSizes: ["1em", "1.75em", "2.5em", "3.5em"],
  space: ["0.5em", "0.75em", "1em", "1.5em"],
  topbarHeight: "70px"
};
