import styled from 'styled-components';
import { color, shadow, space, typography } from 'styled-system';

export const PageTitle = styled.div`
  ${space}
  ${shadow}
  ${color}
  ${typography}

  font-family: ${props => props.theme.fonts.title};
  text-transform: uppercase;
`;
