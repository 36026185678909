import React from "react";

export const Loading = () => {
  return (
    <div class="loading">
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
    </div>
  )
}
