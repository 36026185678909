import React from 'react';

import { Flex, Text } from '../../shared/components';
import { formatSeconds } from '../../../services';
import { usePlayerTime } from '../usePlayerTime';
import { PlayerPageTitle } from '../components';

export const PlayerLobbyPage = () => {
  const { time } = usePlayerTime();

  return (
    <Flex alignItems="center" flexDirection="column" justifyContent="center">
      <PlayerPageTitle color="white" textShadow="title">
        Quiz zacznie
        <br />
        się za:
      </PlayerPageTitle>
      <Flex paddingLeft={1} paddingRight={1}>
        <Text color="white" variant="playerTimer">
          {formatSeconds(time)}
        </Text>
      </Flex>
    </Flex>
  );
};
