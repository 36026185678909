import React, { useContext } from 'react';

import { PubSub } from '../../PubSub';
import { USER_REGISTER_TOPIC_EMIT } from '../../../api/constants';
import { PlayerContext } from '../PlayerContext';

import { LoginPlayerData } from './components';

export const LoginPage = () => {
  const { setScore, setNick } = useContext(PlayerContext);
  const handlePlayerDataSubmit = data => {
    PubSub.publish(USER_REGISTER_TOPIC_EMIT, data);
    setScore(0);
    setNick(data.nick);
  };

  return <LoginPlayerData onSubmit={handlePlayerDataSubmit} />;
};
