import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import styled from 'styled-components';

import football from './pilka_nozna.svg';
import basketball from './siatkowka.svg';
import ski_jumping from './skoki_narciarskie.svg';
import speedway from './zuzel.svg';

const Img = styled.img`
  height: 20px;
  width: 20px;
`;

export const Icon = ({ name }) => {
  const icon = useMemo(() => {
    switch (name) {
      case 'football':
        return football;
      case 'basketball':
        return basketball;
      case 'ski_jumping':
        return ski_jumping;
      case 'speedway':
        return speedway;
      default:
        return null;
    }
  }, [name]);

  return <Img src={icon} />;
};

Icon.propTypes = {
  name: PropTypes.oneOf(['football', 'basketball', 'ski_jumping', 'speedway'])
    .isRequired
};
