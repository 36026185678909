import styled from "styled-components";
import { color, space } from "styled-system";

import { formStyles } from "./formStyles";

export const Input = styled.input`
  ${color}
  ${space}
  ${formStyles}
  width: ${(props) => props.width};

  text-align: center;
  text-transform: uppercase;

  border: ${(props) => (props.notValid ? "2px solid #C50027" : 0)};
  border-radius: ${(props) => props.theme.radii.borderRadius};
  box-shadow: none;
  outline: 0;

  ::placeholder {
    color: #4da6ff;
  }
`;
