import React, { createContext, useEffect } from 'react';

import { useEventWebsockets } from '../../api';

export const EventContext = createContext({});

export const EventContextProvider = ({ children }) => {
  const { connect } = useEventWebsockets();

  useEffect(() => {
    connect();
  }, [connect]);

  return <EventContext.Provider value={{}}>{children}</EventContext.Provider>;
};
