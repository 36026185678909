import { css } from "styled-components";
import { flexbox, layout, space } from "styled-system";

export const flexStyle = css`
  ${layout}
  ${flexbox}
  ${space}

  display: flex;
`;
