import styled from "styled-components";
import { space } from "styled-system";

import { Button } from "../Button";

import { formStyles } from "./formStyles";

export const LoginButton = styled(Button)`
  ${formStyles}
  ${space}
`;