import { useEffect, useState } from 'react';

import { PubSub } from '../PubSub';
import { EVENT_TIME } from '../../api/constants';

export function useEventTime(initialValue = null) {
  const [t, setT] = useState(initialValue);
  useEffect(() => {
    const token = PubSub.subscribe(EVENT_TIME, payload => {
      setT(payload.time);
    });

    return () => {
      PubSub.unsubscribe(token);
    };
  }, [setT]);

  return { time: t };
}
