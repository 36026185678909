import React from 'react';

import {
  Flex,
  Text,
  Page,
  AnswerBox
} from '../../../shared/components';
import {
  EventPageContainer,
  EventPageLogo,
  EventPageContent,
  EventPageTitle,
  EventPageSubTitle
} from '../../components';
import { TimerBox } from '../components';
import { useEventTime } from '../../useEventTime';

export const EventQuestionAnswerPage = ({
  loadingTime,
  questionAnswers,
  questionContent,
  questionNumber
}) => {
  const { time } = useEventTime(loadingTime);

  return (
    <Page>
      <EventPageContainer>
        <EventPageLogo />
        <EventPageContent flexDirection="column">
          <Flex alignItems="flex-start" justifyContent="space-between" px={2}>
            <EventPageTitle>
              Pytanie <Text color="orangeLight" variant="raw">{questionNumber}</Text>
            </EventPageTitle>
            <TimerBox fontSize={2} fontWeight="bold">
              <Text color="orangeLight" variant="page">{time}</Text>
            </TimerBox>
          </Flex>
          <Flex
            justifyContent="center"
            flexDirection="column"
            flexGrow={1}
            pt={1}
            px={2}
          >
            <EventPageSubTitle uppercase textAlign="center">
              {questionContent}
            </EventPageSubTitle>
            <Flex flexGrow={1} mt={1} maxHeight="60%">
              <Flex flexDirection="column" flexGrow={1}>
                <Flex height="50%" mb={0}>
                  <AnswerBox mr={0} width="50%">
                    <Text color="white" fontSize={2} variant="page">
                      {questionAnswers[0]}
                    </Text>
                  </AnswerBox>
                  <AnswerBox ml={0} width="50%">
                    <Text color="white" fontSize={2} variant="page">
                      {questionAnswers[1]}
                    </Text>
                  </AnswerBox>
                </Flex>
                <Flex height="50%" mt={0}>
                  {questionAnswers[2] && (
                    <AnswerBox mr={0} width="50%">
                      <Text color="white" fontSize={2} variant="page">
                        {questionAnswers[2]}
                      </Text>
                    </AnswerBox>
                  )}
                  {questionAnswers[3] && (
                    <AnswerBox ml={0} width="50%">
                      <Text color="white" fontSize={2} variant="page">
                        {questionAnswers[3]}
                      </Text>
                    </AnswerBox>
                  )}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </EventPageContent>
      </EventPageContainer>
    </Page>
  );
};
