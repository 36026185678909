import React from 'react';

import { Page } from '../../shared/components';
import {
  EventPageLogo,
  EventPageContainer,
  EventPageContent,
  EventPageTitle
} from '../components';

export const EventInfoPage = ({ children }) => {
  return (
    <Page>
      <EventPageContainer>
        <EventPageLogo />
        <EventPageContent flexDirection="column" justifyContent="center">
          <EventPageTitle>{children}</EventPageTitle>
        </EventPageContent>
      </EventPageContainer>
    </Page>
  );
};
