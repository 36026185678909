import {
  DISPLAY_GAME_TOPIC_EMIT,
  DISPLAY_GAME_TOPIC_SUBSCRIBE,
  GAME_WAITING_FOR_START_EVENT,
  PLAYER_LOGIN_PAGE,
  PLAYER_INFO_PAGE,
  GAME_CANT_JOIN_EVENT,
  PLAYER_PAGE,
  USER_ID_KEY,
  USER_TOKEN_KEY,
  API_TOKEN_KEY,
  CONNECT_TOPIC_SUBSCRIBE,
  // DISCONNECT_TOPIC_SUBSCRIBE,
  PLAYER_LOGGED_IN_INFO_PAGE,
  CONNECT_ERROR_TOPIC_SUBSCRIBE,
  USER_BAN_TOPIC_SUBSCRIBE,
  PLAYER_LOBBY_PAGE,
  RECONNECT_ERROR_SUBSCRIBE,
  RECONNECTING_SUBSCRIBE,
  PLAYER_RECONNECTING,
  PLAYER_RECONNECTED,
  RECONNECT_SUCCESS_SUBSCRIBE,
} from '../constants';
import { PLAYER_INFO_PAGE_CONTENT } from '../../resources';
import { getItem, setItem } from '../../services';

const removeUserData = () => {
  setItem(USER_ID_KEY, null);
  setItem(USER_TOKEN_KEY, null);
};

/* 
  Attach events to Websocket and PubSub (proxy)
  @param {Websocket} connection - Websocket instance
  @param {PubSub} pubSub - PubSub instance
*/
export const attachInitEvents = (connection, pubSub) => {
  /* 
    EMIT
    PAYLOAD: {
      uid: number; (user id - initial value = -1)
      ut: string; (user token - initial value = "")
      pin: string; (game pin)
      t: string; (token - initial value = undefined)
    }
  */
  connection.on(CONNECT_TOPIC_SUBSCRIBE, () => {
    connection.emit(
      DISPLAY_GAME_TOPIC_EMIT,
      {},
      {
        applyUserData: true
      }
    );
  });

  /* 
    PAYLOAD: {}
  */
  connection.on(CONNECT_ERROR_TOPIC_SUBSCRIBE, () => {
    pubSub.publish(PLAYER_PAGE, {
      page: PLAYER_INFO_PAGE,
      content: PLAYER_INFO_PAGE_CONTENT['CONNECT_ERROR']
    });
  });

  /* 
    PAYLOAD: {}
  */
  // connection.on(DISCONNECT_TOPIC_SUBSCRIBE, () => {
  //   if (connection.isLoggedIn()) {
  //     pubSub.publish(PLAYER_PAGE, {
  //       page: PLAYER_LOGGED_IN_INFO_PAGE,
  //       content: PLAYER_INFO_PAGE_CONTENT['LOST_SERVER_CONNECTION']
  //     });
  //   } else {
  //     pubSub.publish(PLAYER_PAGE, {
  //       page: PLAYER_INFO_PAGE,
  //       content: PLAYER_INFO_PAGE_CONTENT['LOST_SERVER_CONNECTION']
  //     });
  //   }
  // });

  /* 
    PAYLOAD: {}
  */
  connection.on(RECONNECTING_SUBSCRIBE, () => {
    pubSub.publish(PLAYER_RECONNECTING);
  });

  /* 
    PAYLOAD: {}
  */
  connection.on(RECONNECT_SUCCESS_SUBSCRIBE, () => {
    pubSub.publish(PLAYER_RECONNECTED);
  });

  /* 
    PAYLOAD: {}
  */
  connection.on(RECONNECT_ERROR_SUBSCRIBE, () => {
    if (connection.isLoggedIn()) {
      pubSub.publish(PLAYER_PAGE, {
        page: PLAYER_LOGGED_IN_INFO_PAGE,
        content: PLAYER_INFO_PAGE_CONTENT['LOST_SERVER_CONNECTION']
      });
    } else {
      pubSub.publish(PLAYER_PAGE, {
        page: PLAYER_INFO_PAGE,
        content: PLAYER_INFO_PAGE_CONTENT['LOST_SERVER_CONNECTION']
      });
    }
  });

  /* 
    PAYLOAD: {
      n: G_WAITING | G_CANTJOIN; (name)
      r: INACTIVE | INPROGRESS | FINISHED | NOTEXIST | TOOMANY | ""; (reason)
      t: string; (token)
      gsa: date; (game starting time)
      ct: date; (current server time)
    }
  */
  connection.on(DISPLAY_GAME_TOPIC_SUBSCRIBE, payload => {
    const name = payload.n;

    if (name === GAME_WAITING_FOR_START_EVENT) {
      const token = getItem(API_TOKEN_KEY);
      const userId = getItem(USER_ID_KEY);
      const userToken = getItem(USER_TOKEN_KEY);

      if (userId && userToken && token === payload.t) {
        pubSub.publish(PLAYER_PAGE, {
          page: PLAYER_LOBBY_PAGE,
          content: PLAYER_INFO_PAGE_CONTENT['WAITING_FOR_EVENT']
        });
      } else {
        removeUserData();
        setItem(API_TOKEN_KEY, payload.t);
        connection.setToken(payload.t);

        pubSub.publish(PLAYER_PAGE, {
          page: PLAYER_LOGIN_PAGE
        });
      }
    } else if (name === GAME_CANT_JOIN_EVENT) {
      removeUserData();
      const reason = payload.r;

      pubSub.publish(PLAYER_PAGE, {
        page: PLAYER_INFO_PAGE,
        content: PLAYER_INFO_PAGE_CONTENT[reason]
      });
    }
  });

  /* 
    PAYLOAD: {}
  */
  connection.on(USER_BAN_TOPIC_SUBSCRIBE, () => {
    removeUserData();
    pubSub.publish(PLAYER_PAGE, {
      page: PLAYER_INFO_PAGE,
      content: PLAYER_INFO_PAGE_CONTENT['BAN']
    });
  });
};
