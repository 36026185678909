import React from "react";
import { useHistory } from "react-router-dom";

import { LoginGamePIN } from "./LoginGamePIN";

export const HomePage = () => {
  const history = useHistory();

  const handleSubmit = pin => {
    history.push(`/${pin.toLowerCase()}`);
  };

  return <LoginGamePIN onSubmit={handleSubmit} />;
};
