import React from 'react';
import styled from 'styled-components';

import { Flex, Page, Text } from '../../shared/components';
import {
  EventPageContainer,
  EventPageLogo,
  EventPageContent,
  EventPageTitle
} from '../components';
import { transparentBackground } from '../../shared/css';

const PodiumFlex = styled(Flex)`
  ${transparentBackground}

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 33%;

  &:before {
    color: ${props => props.theme.colors.white};
    font-size: 12.5em;
    opacity: 0.1;
    position: absolute;
  }
`;

const PodiumFlexFirst = styled(PodiumFlex)`
  &:before {
    content: '1';
  }
`;

const PodiumFlexSecond = styled(PodiumFlex)`
  &:before {
    content: '2';
  }
`;

const PodiumFlexThird = styled(PodiumFlex)`
  &:before {
    content: '3';
  }
`;

export const EventTopScorePage = ({ rank }) => {
  return (
    <Page>
      <EventPageContainer>
        <EventPageLogo />
        <EventPageContent flexDirection="column">
          <Flex justifyContent="center" px={2}>
            <EventPageTitle color="orangeLight">
              <Text color="orangeLight" variant="raw">Zwycięzcy</Text>
            </EventPageTitle>
          </Flex>
          <Flex
            alignItems="center"
            justifyContent="center"
            flexGrow={1}
            pt={1}
            px={3}
          >
            <Flex alignItems="flex-end" flexGrow={1}>
              {rank[1] && (
                <PodiumFlexSecond height="20em" mr={1}>
                  <Text
                    color="white"
                    fontSize={0}
                    fontWeight="bold"
                    mb={0}
                    variant="page"
                  >
                    {rank[1].nick}
                  </Text>
                  <Text color="white" fontSize={0}>
                    {rank[1].score} pkt
                  </Text>
                </PodiumFlexSecond>
              )}
              {rank[0] && (
                <PodiumFlexFirst height="25em" mr={1}>
                  <Text
                    color="white"
                    fontSize={0}
                    fontWeight="bold"
                    mb={0}
                    variant="page"
                  >
                    {rank[0].nick}
                  </Text>
                  <Text color="white" fontSize={0}>
                    {rank[0].score} pkt
                  </Text>
                </PodiumFlexFirst>
              )}
              {rank[2] && (
                <PodiumFlexThird height="15em">
                  <Text
                    color="white"
                    fontSize={0}
                    fontWeight="bold"
                    mb={0}
                    variant="page"
                  >
                    {rank[2].nick}
                  </Text>
                  <Text color="white" fontSize={0}>
                    {rank[2].score} pkt
                  </Text>
                </PodiumFlexThird>
              )}
            </Flex>
          </Flex>
        </EventPageContent>
      </EventPageContainer>
    </Page>
  );
};
