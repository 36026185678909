import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";

import { STSLogo, Input, LoginButton, Flex } from "../../shared/components";

export const LoginGamePIN = ({ onSubmit }) => {
  const [pin, setPin] = useState("");

  const handleSubmit = useCallback(() => {
    onSubmit(pin);
  }, [onSubmit, pin]);

  return (
    <>
      <STSLogo mb={2} width="200px" />
      <Flex flexDirection="column" maxWidth="200px" width="50%">
        <Input
          bg="white"
          color="blueLight"
          py={0}
          mb={2}
          placeholder="PIN do gry"
          onChange={(event) => setPin(event.target.value)}
          value={pin}
        />
        <LoginButton onClick={handleSubmit}>
          Zatwierdź
        </LoginButton>
      </Flex>
    </>
  );
};

LoginGamePIN.propTypes = {
  onSubmit: PropTypes.func.isRequired
};
