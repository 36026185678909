import React from "react";
import styled from "styled-components";
import { useMemo } from "react";

import { Flex, Page, Text } from "../../shared/components";
import {
  EventPageContainer,
  EventPageLogo,
  EventPageContent,
  EventPageTitle,
  EventPageSubTitle
} from "../components";
import { transparentBackground } from "../../shared/css";

const PositionBox = styled(Flex)`
  ${transparentBackground}
`;

const RankContainer = styled(Flex)`
  > * {
    &:not(:first-child) {
      margin-top: ${props => props.theme.space[0]};
    }
  }
`;

export const EventRankPage = ({ correctAnswer, rank }) => {
  const localRank = useMemo(() => rank.filter((_, i) => i < 5), [rank]);

  return (
    <Page>
      <EventPageContainer>
        <EventPageLogo />
        <EventPageContent flexDirection="column">
          <Flex justifyContent="center" px={2}>
            <EventPageTitle>
              <Text color="orangeLight" variant="raw">Ranking</Text>
            </EventPageTitle>
          </Flex>
          <RankContainer
            justifyContent="center"
            flexDirection="column"
            flexGrow={1}
            pt={1}
            px={3}
          >
            <EventPageSubTitle mb={0} textAlign="center">
              Poprawna odpowiedź: <Text color="orangeLight" variant="raw">{correctAnswer}</Text>
            </EventPageSubTitle>
            {localRank.map((item, i) => (
              <PositionBox justifyContent="space-between" p={0} key={`${item.nick}_${i}`}>
                <Text color="white" fontSize={0} fontWeight="bold" variant="page">
                  {item.nick}
                </Text>
                <Text color="white" fontSize={0} variant="page">
                  {item.score}
                </Text>
              </PositionBox>
            ))}
          </RankContainer>
        </EventPageContent>
      </EventPageContainer>
    </Page>
  );
};
