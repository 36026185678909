import React from "react";
import styled from "styled-components";
import { isArray } from "lodash";
import {
  Container as StyledContainer,
  Row as StyledRow,
  Col as StyledCol,
  GridThemeProvider,
} from "styled-bootstrap-grid";
import { useState } from "react";

import { Text, Flex, AnswerBox } from "../../../shared/components";
import { usePlayerTime } from "../../usePlayerTime";
import { QuestionTitle } from "../../components";

const THEME = {
  breakpoints: {
    md: 568,
  },
  container: {
    // 8 is 0.5em on mobile
    padding: 8,
  },
  row: {
    padding: 8,
  },
  col: {
    padding: 8,
  },
};

const Container = styled(StyledContainer)`
  height: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-direction: column;
`;

const Row = styled(StyledRow)`
  height: 100%;
  width: 100%;
  flex-grow: 1;
`;

const Col = styled(StyledCol)`
  padding-bottom: ${(props) => props.theme.space[0]};
  padding-top: ${(props) => props.theme.space[0]};
`;

const Wrapper = styled(Flex)`
  overflow-y: auto;
`;

export const PlayerQuestionAnswerPage = ({
  loadingTime,
  questionAnswers,
  questionContent,
  questionId,
  questionNumber,
  onAnswerClick,
}) => {
  const { time } = usePlayerTime(loadingTime);
  const [clicked, setClicked] = useState(null);
  const handleAnswerClick = (index) => {
    if (!clicked) {
      onAnswerClick({
        answerId: index + 1,
        questionNumber,
        questionId,
      });
      setClicked(index);
    }
  };

  return (
    <Wrapper flexDirection="column" flexGrow={1} height="100%" px={0} py={3}>
      {clicked !== null ? (
        <Text
          color="orangeLight"
          fontSize={1}
          mb={2}
          textAlign="center"
          uppercase
          variant="page"
        >
          Odpowiedź nr {clicked + 1}
        </Text>
      ) : (
        <Text
          color="orangeLight"
          fontSize={1}
          mb={2}
          textAlign="center"
          uppercase
          variant="page"
        >
          Pozostało {time} sekund
        </Text>
      )}
      <QuestionTitle color="white" mb={2}>
        {questionContent}
      </QuestionTitle>
      <GridThemeProvider gridTheme={THEME}>
        <Container fluid>
          <Row>
            {isArray(questionAnswers) &&
              questionAnswers.map((answer, i) => {
                if (answer === null) {
                  return null;
                }

                return (
                  <Col sm={12} md={6} key={`${answer}_${i}`}>
                    <span onClick={() => handleAnswerClick(i)}>
                      <AnswerBox
                        clicked={clicked === i}
                        mb={i === 3 ? undefined : 0}
                      >
                        <Text color="white" fontSize={0} variant="page">
                          {answer}
                        </Text>
                      </AnswerBox>
                    </span>
                  </Col>
                );
              })}
          </Row>
        </Container>
      </GridThemeProvider>
    </Wrapper>
  );
};
