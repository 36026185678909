let uuid = 0;

export class PubSub {
  topics = {};

  subscribe = (topic, listener) => {
    if (!this.topics.hasOwnProperty(topic)) {
      this.topics[topic] = {};
    }

    const token = `uuid_${uuid++}`;
    this.topics[topic][token] = listener;

    return token;
  };

  subscribeOnce = (topic, listener) => {
    const self = this;
    const token = this.subscribe(topic, function() {
      self.unsubscribe(token);
      // @ts-ignore
      listener.apply(this, arguments);
    });

    return token;
  };

  unsubscribe = token => {
    for (const topic in this.topics) {
      if (
        this.topics.hasOwnProperty(topic) &&
        this.topics[topic].hasOwnProperty(token)
      ) {
        delete this.topics[topic][token];
      }
    }
  };

  publish = (topic, data) => {
    if (!this.topics.hasOwnProperty(topic)) {
      return;
    }

    for (const token in this.topics[topic]) {
      if (this.topics[topic].hasOwnProperty(token)) {
        setTimeout(this.topics[topic][token](data), 0);
      }
    }
  };
}
