import { css } from "styled-components";

import { orangeGradient, blueGradient } from "./colors";

export const transparentBackgroundLight = css`
  border: solid 0.8px rgba(255, 255, 255, 0.5);
  background-color: rgba(135, 160, 204, 0.15);
`;

export const transparentBackground = css`
  border: solid 0.8px rgba(255, 255, 255, 0.5);
  background-color: rgba(135, 160, 204, 0.35);
`;

export const gradientBackground = css`
  &:before {
    content: "";
    width: 100%;
    height: 100%;
    transform: skew(-10deg);
    position: absolute;
    left: calc(-50%);
    top: 0;
    z-index: -1;
    ${orangeGradient}
  }

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    transform: skew(-10deg);
    position: absolute;
    left: calc(50%);
    top: 0;
    z-index: -1;
    ${blueGradient}
  }
`;
