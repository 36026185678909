import {
  EVENT_PAGE,
  EVENT_LOBBY_PAGE,
  EVENT_INFO_PAGE,
  EVENT_SCREEN_INIT_EMIT,
  EVENT_SCREEN_INIT_SUBSCRIBE,
  EVENT_SCREEN_READY_SUBSCRIBE,
  EVENT_SCREEN_QUESTION_LOAD_SUBSCRIBE,
  EVENT_QUESTION_LOADING_PAGE,
  EVENT_SCREEN_QUESTION_ANSWER_SUBSCRIBE,
  EVENT_QUESTION_ANSWER_PAGE,
  EVENT_SCREEN_QUESTION_RESULT_SUBSCRIBE,
  EVENT_RANK_PAGE,
  EVENT_SCREEN_TOP_RANK_SUBSCRIBE,
  EVENT_TOP_SCORE_PAGE,
  CONNECT_TOPIC_SUBSCRIBE,
  CONNECT_ERROR_TOPIC_SUBSCRIBE,
  EVENT_SCREEN_UPDATE_TIME_SUBSCRIBE,
  EVENT_TIME,
  RECONNECT_ERROR_SUBSCRIBE,
  EVENT_RECONNECTING,
  RECONNECTING_SUBSCRIBE,
  EVENT_RECONNECTED,
  RECONNECT_SUCCESS_SUBSCRIBE
} from '../constants';
import { EVENT_INFO_PAGE_CONTENT } from '../../resources';

const normalizeInit = payload => ({
  time: payload.start_at
});

const normalizeQuestionLoad = payload => ({
  loadingTime: payload.time,
  questionContent: payload.question,
  questionNumber: payload.qnum
});

const normalizeQuestionAnswer = payload => ({
  questionAnswers: payload.answers,
  questionContent: payload.question,
  questionId: payload.qid,
  questionNumber: payload.qnum,
  loadingTime: payload.time
});

const normalizeQuestionResult = payload => ({
  correctAnswer: payload.correct_answer_str,
  rank: payload.rank
});

const normalizeTopRank = payload => ({
  rank: payload.rank
});

/* 
  Attach events to Websocket and PubSub (proxy)
  @param {Websocket} connection - Websocket instance
  @param {PubSub} pubSub - PubSub instance
*/
export const attachEventEvents = (connection, pubSub) => {
  /* 
    EMIT
    PAYLOAD: {
      pin: string; (game pin)
      t: string; (token - initial value = undefined)
    }
  */
  connection.on(CONNECT_TOPIC_SUBSCRIBE, () => {
    connection.emit(EVENT_SCREEN_INIT_EMIT, {});
  });

  connection.on(CONNECT_ERROR_TOPIC_SUBSCRIBE, () => {
    pubSub.publish(EVENT_PAGE, {
      page: EVENT_INFO_PAGE,
      content: EVENT_INFO_PAGE_CONTENT['CONNECT_ERROR']
    });
  });

  /* 
    PAYLOAD: {}
  */
  connection.on(RECONNECTING_SUBSCRIBE, () => {
    pubSub.publish(EVENT_RECONNECTING);
  });

  /* 
    PAYLOAD: {}
  */
  connection.on(RECONNECT_SUCCESS_SUBSCRIBE, () => {
    pubSub.publish(EVENT_RECONNECTED);
  });

  connection.on(RECONNECT_ERROR_SUBSCRIBE, () => {
    pubSub.publish(EVENT_PAGE, {
      page: EVENT_INFO_PAGE,
      content: EVENT_INFO_PAGE_CONTENT['LOST_SERVER_CONNECTION']
    });
  });

  /* 
    PAYLOAD: {
      pin: string; (game pin)
      token: string; (token)
      id: number;
      start_at: number; (date)
      time_now: number; (date)
    }
  */
  connection.on(EVENT_SCREEN_INIT_SUBSCRIBE, payload => {
    pubSub.publish(EVENT_PAGE, {
      page: EVENT_LOBBY_PAGE,
      gamePin: connection.pin,
      ...normalizeInit(payload)
    });
  });

  connection.on(EVENT_SCREEN_READY_SUBSCRIBE, () => {
    pubSub.publish(EVENT_PAGE, {
      page: EVENT_INFO_PAGE,
      content: EVENT_INFO_PAGE_CONTENT['GAME_READY']
    });
  });

  connection.on(EVENT_SCREEN_QUESTION_LOAD_SUBSCRIBE, payload => {
    pubSub.publish(EVENT_PAGE, {
      page: EVENT_QUESTION_LOADING_PAGE,
      ...normalizeQuestionLoad(payload)
    });
  });

  connection.on(EVENT_SCREEN_QUESTION_ANSWER_SUBSCRIBE, payload => {
    pubSub.publish(EVENT_PAGE, {
      page: EVENT_QUESTION_ANSWER_PAGE,
      ...normalizeQuestionAnswer(payload)
    });
  });

  connection.on(EVENT_SCREEN_QUESTION_RESULT_SUBSCRIBE, payload => {
    pubSub.publish(EVENT_PAGE, {
      page: EVENT_RANK_PAGE,
      ...normalizeQuestionResult(payload)
    });
  });

  connection.on(EVENT_SCREEN_TOP_RANK_SUBSCRIBE, payload => {
    pubSub.publish(EVENT_PAGE, {
      page: EVENT_TOP_SCORE_PAGE,
      ...normalizeTopRank(payload)
    });
  });

  /* 
    PAYLOAD: {
      t: number;
    }
  */
  connection.on(EVENT_SCREEN_UPDATE_TIME_SUBSCRIBE, payload => {
    pubSub.publish(EVENT_TIME, {
      time: payload.t
    });
  });
};
