import React, { useContext } from "react";
import styled from "styled-components";
import { space } from "styled-system";

import { Flex, STSLogo, Text } from "../../shared/components";
import { blueGradient, orangeGradient } from "../../shared/css";
import { PlayerContext } from "../PlayerContext";

const TopBar = styled.div`
  ${space}
  ${blueGradient}
  border-bottom: ${(props) => `2px solid ${props.theme.colors.blueLight}`};
  height: ${(props) => props.theme.topbarHeight};
  min-height: ${(props) => props.theme.topbarHeight};
  position: relative;

  &:before {
    ${orangeGradient}
    content: "";
    width: ${(props) => props.theme.topbarHeight};
    height: ${(props) => props.theme.topbarHeight};
    transform: skew(-10deg);
    position: absolute;
    left: -30px;
    top: 0;
    z-index: 1;
  }
`;

const PointsBox = styled.div`
  ${space}
  ${orangeGradient}

  border-radius: ${(props) => props.theme.radii.borderRadius};
`;

export const PlayerBar = ({ showPlayerData = true }) => {
  const { nick, score } = useContext(PlayerContext);
  return (
    <TopBar px={1}>
      <Flex alignItems="center" justifyContent="space-between" height="100%">
        <STSLogo maxHeight="40px" zIndex={2} />
        {showPlayerData && (
          <Flex alignItems="center">
            <Text color="white" fontWeight="bold" variant="page">
              {nick}
            </Text>
            <PointsBox ml={1} py={0} px={1}>
              <Text color="white" fontWeight="bold" variant="page">
                {`Punkty ${score}`}
              </Text>
            </PointsBox>
          </Flex>
        )}
      </Flex>
    </TopBar>
  );
};
