import {
  USER_REGISTER_TOPIC_EMIT,
  USER_REGISTER_TOPIC_SUBSCRIBE,
  PLAYER_LOBBY_PAGE,
  PLAYER_PAGE,
  USER_TOKEN_KEY,
  USER_ID_KEY,
  USER_REGISTER_SUCCESS_EVENT,
  USER_REGISTER_VALIDATION_EVENT,
  PLAYER_VALIDATION,
  GAME_PIN_KEY
} from '../constants';
import { setItem } from '../../services';

/* 
  Attach events to Websocket and PubSub (proxy)
  @param {Websocket} connection - Websocket instance
  @param {PubSub} pubSub - PubSub instance
*/
export const attachRegisterEvents = (connection, pubSub) => {
  /* 
    EMIT
    PAYLOAD: {
      nick: string;
      email: string;
      phone: string;
      agreement: boolean[];
      pin: string; (game pin)
      t: string; (token)
    }
  */
  pubSub.subscribe(USER_REGISTER_TOPIC_EMIT, payload => {
    connection.emit(USER_REGISTER_TOPIC_EMIT, {
      ...payload,
      agreement: [payload.dataProcessingAgreement, payload.adultAgreement, payload.rulesAgreement, payload.marketAgreement]
    });
  });

  /* 
    PAYLOAD: {
      n: R_USER;
      r: [
        0: number;
        1: string; (token)
      ];
      t: string;
      gsa: number;
      ct: number;
    }
  */
  connection.on(USER_REGISTER_TOPIC_SUBSCRIBE, payload => {
    if (payload.n === USER_REGISTER_SUCCESS_EVENT) {
      setItem(USER_ID_KEY, payload.r[0]);
      setItem(USER_TOKEN_KEY, payload.r[1]);
      setItem(GAME_PIN_KEY, connection.pin);
      connection.setUserData(payload);

      pubSub.publish(PLAYER_PAGE, {
        page: PLAYER_LOBBY_PAGE,
        ...payload
      });
    } else if (payload.n === USER_REGISTER_VALIDATION_EVENT) {
      pubSub.publish(PLAYER_VALIDATION, payload.r);
    }
  });
};
