import React from 'react';
import styled from 'styled-components';

import { orangeGradient } from '../../shared/css';
import { STSLogo } from '../../shared/components';

const EventPageLogoContainer = styled.div`
  &:before {
    content: '';
    width: 400%;
    height: 100%;
    transform: skew(-10deg);
    position: absolute;
    left: -330%;
    top: 0;
    z-index: -1;
    ${orangeGradient}
  }

  position: relative;
  z-index: 1;
  height: 100%;
  width: 25%;
`;

const Logo = styled(STSLogo)`
  position: absolute;
  right: -17%;
  top: 50%;
  max-width: 100%;
  transform: translateY(-50%);
`;

export const EventPageLogo = () => {
  return (
    <EventPageLogoContainer>
      <Logo />
    </EventPageLogoContainer>
  );
};
