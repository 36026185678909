import styled from "styled-components";

import { PageContainer } from "../../shared/components";
import { blueGradient } from "../../shared/css";

export const EventPageContainer = styled(PageContainer)`
  ${blueGradient}
  display: flex;
  flex-direction: row;
  height: 100%;
`;
