import styled from 'styled-components';

import { Text } from '../../../shared/components';

export const TimerBox = styled(Text)`
  background-color: ${props => props.theme.colors.white};
  border-radius: 50%;
  width: 2em;
  height: 2em;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;
