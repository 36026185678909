import React, { useEffect, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { flexbox, typography, layout } from 'styled-system';

import { EVENT_THEME } from '../shared/theme';
import { PubSub } from '../PubSub';
import {
  EVENT_PAGE,
  EVENT_LOBBY_PAGE,
  EVENT_QUESTION_LOADING_PAGE,
  EVENT_QUESTION_ANSWER_PAGE,
  EVENT_QUESTION_RESULT_PAGE,
  EVENT_TOP_SCORE_PAGE,
  EVENT_INFO_PAGE,
  EVENT_RANK_PAGE,
  EVENT_RECONNECTING,
  EVENT_RECONNECTED
} from '../../api/constants';

import { EventTopScorePage } from './TopScore';
import { EventQuestionResultPage } from './Question';
import { EventLobbyPage } from './Lobby';
import { EventQuestionLoadingPage } from './Question';
import { EventQuestionAnswerPage } from './Question';
import { EventRankPage } from './Rank';
import { EventInfoPage } from './Lobby/EventInfoPage';
import { Loading } from '../shared/components';

const EventPagesContainer = styled.div`
  ${flexbox}
  ${layout}
  ${typography}

  position: relative;
`;

export const EventPages = () => {
  const [reconnecting, setReconnecting] = useState(false);
  const [currentPage, setCurrentPage] = useState({
    page: null,
    payload: {}
  });

  useEffect(() => {
    const subscribeFn = payload => {
      setCurrentPage({ page: payload.page, payload });
    };

    PubSub.subscribe(EVENT_PAGE, subscribeFn);
    PubSub.subscribe(EVENT_RECONNECTING, () => {
      setReconnecting(true);
    });
    PubSub.subscribe(EVENT_RECONNECTED, () => {
      setReconnecting(false);
    })
  }, []);

  return (
    <ThemeProvider theme={EVENT_THEME}>
      <EventPagesContainer
        display="flex"
        flexDirection="column"
        fontSize={['8px', '12px', '16px', '20px']}
        height="100%"
      >
        {reconnecting &&
          <div style={{
            position: "absolute",
            bottom: "0.5em",
            left: "0.5em",
            zIndex: 2
          }}>
            <Loading />
          </div>
        }
        {!currentPage.page && <EventInfoPage />}
        {currentPage.page === EVENT_LOBBY_PAGE && (
          <EventLobbyPage gamePin={currentPage.payload.gamePin} />
        )}
        {currentPage.page === EVENT_INFO_PAGE && (
          <EventInfoPage children={currentPage.payload.content} />
        )}
        {currentPage.page === EVENT_QUESTION_LOADING_PAGE && (
          <EventQuestionLoadingPage
            animationTime={currentPage.payload.loadingTime}
            questionContent={currentPage.payload.questionContent}
            questionNumber={currentPage.payload.questionNumber}
          />
        )}
        {currentPage.page === EVENT_QUESTION_ANSWER_PAGE && (
          <EventQuestionAnswerPage
            loadingTime={currentPage.payload.loadingTime}
            questionAnswers={currentPage.payload.questionAnswers}
            questionContent={currentPage.payload.questionContent}
            questionNumber={currentPage.payload.questionNumber}
          />
        )}
        {currentPage.page === EVENT_QUESTION_RESULT_PAGE && (
          <EventQuestionResultPage />
        )}
        {currentPage.page === EVENT_RANK_PAGE && (
          <EventRankPage
            correctAnswer={currentPage.payload.correctAnswer}
            rank={currentPage.payload.rank}
          />
        )}
        {currentPage.page === EVENT_TOP_SCORE_PAGE && (
          <EventTopScorePage rank={currentPage.payload.rank} />
        )}
      </EventPagesContainer>
    </ThemeProvider>
  );
};
