export const setItem = (key, data) => {
  if (data) {
    localStorage.setItem(key, JSON.stringify(data));
  } else {
    localStorage.removeItem(key);
  }
};

export const getItem = key => {
  const payload = localStorage.getItem(key);
  if (payload) {
    try {
      return JSON.parse(payload);
    } catch (error) {
      return undefined;
    }
  }
  return undefined;
};
