import React from "react";
import styled from "styled-components";

import { Text, PageTitle, Flex } from "../../../shared/components";
import { usePlayerTime } from "../../usePlayerTime";
import { PlayerPageTitle, QuestionTitle } from "../../components";

const TimerBox = styled(PageTitle)`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 50%;
  font-size: ${(props) => props.theme.fontSizes[2]};
  width: 2em;
  height: 2em;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PlayerQuestionLoadingPage = ({
  animationTime,
  questionContent,
  questionNumber,
}) => {
  const { time } = usePlayerTime(animationTime);

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      flexGrow={1}
      height="100%"
      px={0}
      py={3}
    >
      <PlayerPageTitle color="orangeLight" mb={2}>
        Pytanie {questionNumber}
      </PlayerPageTitle>
      <QuestionTitle color="white" mb={2}>
        {questionContent}
      </QuestionTitle>
      <TimerBox color="orangeLight" mb={2}>
        {time}
      </TimerBox>
      <Text color="white" fontSize={1} uppercase variant="page">
        Przygotuj się...
      </Text>
    </Flex>
  );
};
